<template>
  <div class="searchresults_topber mobail">
    <h5>{{$t("sabre.search-result.return-flight-selection", {count: filteredItems.length})}}</h5>
    <div class="d-flex align-items-center justify-content-between">
      <button v-b-modal.adjustbtnModal class="adjustbtn" type="button"><i class="fa-solid fa-sliders"></i></button>

      <b-modal
        id="adjustbtnModal"
        centered
        size="lg"
        :hide-footer="true"
        dialog-class="adjustbtnModal modal-dialog-centered"
        v-model="filterModal"
      >
        <template #modal-header="{ close }">
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="() => close()"></button>

          <div class="filtter_head">
            <h3>{{$t("sabre.search-result.filter-result-by")}}</h3>
          </div>
        </template>

        <div class="adjust_fillterbox">
          <stops-filter propsClassName="adjust_fillterbox_top text-right"  type="cleanable"/>

          <div class="adjust_fillterbox_medial">
            <div class="accordion" id="accordionExample">
              <flight-duration-filter type="cleanable"/>
              <airline-filter type="cleanable"/>
              <airport-filter type="cleanable"/>
              <time-filter type="cleanable"/>
              <budget-filter type="cleanable"/>
           </div>
          </div>
         </div>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
            <button class="cleanup_btn" @click="() => clean()">{{$t("sabre.buttons.clean")}}</button>
            <button class="save_btn" @click="() => save()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>
      </b-modal>

      <div class="btn-group">
        <button v-b-modal.filtermodal8 class="btn btn-outline-primary btnnoborder dropdown-toggle" type="button">
          {{$t("sabre.search-result.sort-recommended")}}
        </button>
      </div>

      <b-modal
        id="filtermodal8"
        centered
        size="lg"
        :hide-footer="true"
        dialog-class="adjustbtnModal modal-dialog-centered"
        v-model="sortModal"
      >
        <template #modal-header="{ close }">
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="() => close()"></button>

          <div class="filtter_head">
          <h3>{{$t("sabre.search-result.sort-recommended")}}</h3>
        </div>
        </template>

        <search-result-sort-dropdown/>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
            <button class="save_btn" @click="() => {sortModal = false;}">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';

export default {
  name: 'search-mobile-down-pane',
  components: {
    BModal,
    StopsFilter: () => import('./filterPanelAtom/stopsFilter'),
    AirlineFilter: () => import('./filterPanelAtom/airlineFilter'),
    AirportFilter: () => import('./filterPanelAtom/airportFilter'),
    TimeFilter: () => import('./filterPanelAtom/timeFilter'),
    BudgetFilter: () => import('./filterPanelAtom/budgetFilter'),
    FlightDurationFilter: () => import('./filterPanelAtom/flightDurationFilter'),
    SearchResultSortDropdown: () => import('../sortContent/searchResultSortDropdown'),
  },
  computed: {
    ...mapGetters({
      filteredItems: 'GET_SABRE_FLIGHT_FILTERED_ITEMS',
    }),
  },
  data() {
    return {
      filterModal: false,
      sortModal: false,
    };
  },
  mounted() {
    this.$root.$on('show-mobile-filter-modal', () => { this.filterModal = true; });
  },
  methods: {
    save() {
      this.filterModal = false;
      this.$children[0].$children[0].$children.find((child) => child.type === 'cleanable').applyFilter();
    },
    clean() {
      this.$children[0].$children[0].$children.find((child) => child.type === 'cleanable').forEach(((child) => {
        child.clean();
      }));
    },
  },
};
</script>
